import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import MyNav from '../component/MyNav/MyNav';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Brachytherapy from '../pages/Brachytherapy/Brachy';
import RadiationTherapy from '../pages/RadCenter/RadCenter';
import ASCManagement from '../pages/ASCManagement/ASCManagement';
import RCM from '../pages/RevCycleMgmt/RCM';
import Contact from '../pages/Contact/Contact';
import MyFooter from '../component/MyFooter/MyFooter';
import './App.scss';

const PublicRoute = ({ component: Component, ...rest }) => {
  const routeChecker = props => (
    (<Component { ...props }/>)
  );
  return <Route {... rest} render={props => routeChecker(props)}/>;
};

class App extends React.Component {
  state = {
    footerHeight: 0,
  }

  heightGetter = () => {
    const height = document.getElementById('footer').offsetHeight;
    this.setState({ footerHeight: height });
  }

  render() {
    const footerSizing = {
      minHeight: (window.innerHeight - this.state.footerHeight - 1),
    };

    return (
      <div className="App">
      <div
      id='no-footer'
      style= {footerSizing}
      >
        <BrowserRouter>
          <React.Fragment>
            <MyNav />
            <div className='main-content'>
              <Switch>
                <PublicRoute path='/' exact component={Home} />
                <PublicRoute path='/about' component={About} />
                <PublicRoute path='/brachytherapy' component={Brachytherapy} />
                <PublicRoute path='/RadiationTherapy' component={RadiationTherapy} />
                <PublicRoute path='/ASC_Management' component={ASCManagement} />
                <PublicRoute path='/Revenue_Cycle_Management' component={RCM} />
                <PublicRoute path='/Contact_Us' component={Contact} />
              </Switch>
            </div>
          </React.Fragment>
        </BrowserRouter>
        </div>
        <MyFooter heightGetter={this.heightGetter}/>
      </div>
    );
  }
}

export default App;
