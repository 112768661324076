import React from 'react';
import './About.scss';

class About extends React.Component {
  render() {
    return (
      <div>
        <div className='about-sub-heading'>
          <h3 className='container'>
            Prologics is headquartered in the Greater Nashville, Tennessee region,
            founded by physicians and business experts who have organized
            an exceptionally adept development, marketing, operations, reimbursement
            and financial team.
          </h3>
        </div>
        <div className='container about-container'>
          <h1 className='about-title'>About us</h1>
          <p>
            We have more than 100 years of combined experience in building new sources
            of profitable revenue, improving operational efficiencies, and maximizing
            returns on investment.
          </p>
          <div className='about-text-container'>
            <div className='about-flexy'>
              <i className='fas fa-key fa-7x' id='turnkey-icon'></i>
              <p>
                Prologics provides specialty radiation oncology services as well as facility
                management in the U.S. The company was founded in 2005 to provide industry-leading
                products and services that fulfill clinical specifications and business solutions
                for providers specializing in radiation oncology, urology, ENT and more. What makes
                us unique is the combination of turnkey solutions, expert clinicians,
                and an experienced management team, which is peerless in
                the industry.
              </p>
            </div>
            <div className='about-flexy'>
              <i className='fab fa-react fa-7x' id='atom-icon'></i>
              <p>
                The company’s mission is to provide industry-leading services that result
                in positive clinical and financial outcomes with top notch business
                services that support hospitals, physicians and ambulatory surgery centers.
                Delivering high quality services requires a blend of the latest technologies
                and patient-centered care models. Prologics ensures your institution applies
                these principles.
              </p>
            </div>
          </div>
          <p className='about-final'>
              If you're interested in one of our services - just give us a call or
              send us an email. We take the time to understand your interests and
              provide a simple solution to get you into a new service line or improve
              efficiencies in your current business. We are agile enough to customize
              a real and viable solution for your business while having sufficient
              resources to provide you with the best solutions possible.
          </p>
        </div>
      </div>
    );
  }
}

export default About;
