import React from 'react';
import './RadCenter.scss';

class RadiationTherapy extends React.Component {
  render() {
    return (
      <div>
        <div className='radiation-banner'>
          <h3 className='sub-heading container'>
            The Prologics team has a stellar record of designing, building and managing
            outpatient radiation therapy centers. Investors, physicians, clinicians and
            patients trust our performance.
          </h3>
        </div>
        <div className='container radiation-content'>
          <h1 className='rad-content-titles rad-main-title'>Prologics Outpatient Radiation Therapy Centers</h1>
          <p>
            The development of a new radiation therapy center is a huge undertaking. In
            fact, the initial capitalization of a center with one or two linear accelerators
            averages $4 to $8 million. This capital is required to buy or lease the building,
            purchase equipment, build the vault(s), fund the tenant improvements, purchase
            furnishings, hire staff and fund the start-up phase of the venture until cash
            flows. We know how to make these ventures profitable by securing a referral base,
            running an efficient operation and ensuring payments from third-party payers.
          </p>
          <p>
            The challenges to starting a center include meeting complex regulatory compliance
            laws, developing a deal structure that works for all parties, gaining access to
            capital, finding the right radiation oncologist, obtaining payer contracts,
            developing a competitive advantage, attracting and retaining experienced staff and
            operating the business so that it continues to attract patients.
          </p>
          <p>
            Our development process is comprised of a number of steps. The first step is to
            conduct an analysis that determines the financial feasibility of a center.
            If feasible, the next steps are to develop relationships with key
            customers, formulate a detailed business plan and source competitive capital. If a
            strategic plan is established, the next steps are to generate a letter of intent and
            definitive documentation, establish timelines for the project and assign a site
            manager to develop the project. This manager will implement the business plan, which
            includes the marketing, operations, reimbursement and financial strategies for the
            center.
          </p>
          <h4 className='rad-content-titles'>IMRT/IGRT Services</h4>
          <p>
          With experience and expertise in radiation therapy and practice management,
          Prologics is well-equipped to facilitate in the construction
          of an external beam therapy (IMRT/IGRT) center. Our team can lead your organization
          through the process of developing and manageing a cancer center, including any of
          the following services:
          </p>
          <ul className='imrt-list'>
            <li>
              <span>Design, engineer, construct and commission</span>
            </li>
            <li>
              <span>Secure financing for equipment, systems and construction</span>
            </li>
            <li>
              <span>Select equipment and systems and negotiate purchases</span>
            </li>
            <li>
              <span>Recruit and hire all staff</span>
            </li>
            <li>
              <span>Obtain all relevant licensing and operational permits</span>
            </li>
            <li>
              <span>
                Establish operating guidelines including policy, procedures and compliance
              </span>
            </li>
            <li className='last-list-rad'>
              <span>Train and manage all staff</span>
            </li>
            <li className='last-list-rad'>
              <span>Manage all business-related functions</span>
            </li>
            <li className='last-list-rad'>
              <span>Provide business oversight and accountability</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default RadiationTherapy;
