import React from 'react';
import './Home.scss';

class Home extends React.Component {
  render() {
    return (
      <div>
        <div className='home-title'>
        </div>
        <div className='container'>
          <h3 className='sub-heading home-sub-heading'>
            We support physicians, hospitals, and ambulatory surgery centers
            in the provision of radiation oncology services and facility management,
            while ensuring each patient is treated as the top priority.
          </h3>
          <h4 className='home-h4'>Comprehensive Service Overview</h4>
          <div className='home-services'>
            <a href='/RadiationTherapy'>
              <p className='home-services-detail'>
                <i className='far fa-hospital fa-3x home-icon' id='imrt-icon'></i>
                Radiation Therapy Centers
              </p>
            </a>
            <a href='/brachytherapy'>
              <p className='home-services-detail'>
                <i className='fas fa-map-pin fa-3x home-icon' id='brachy-icon'></i>
                Brachytherapy Programs
              </p>
            </a>
            <a href='/Revenue_Cycle_Management'>
              <p className='home-services-detail'>
                <i className='fas fa-chart-line fa-3x home-icon' id='rcm-icon'></i>
                Revenue Cycle Management
              </p>
            </a>
            <a href='/ASC_Management'>
              <p className='home-services-detail'>
                <i className='fas fa-file-invoice fa-3x home-icon' id='management-icon'></i>
                ASC Management Services
              </p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
