import React from 'react';
import './Contact.scss';

class Contact extends React.Component {
  state = {
    mapStyling: {
      border: '1px solid black',
    },
  }

  render() {
    return (
      <div className='j'>
        <div className='container'>
          <h1 className='contact-title'>Contact Us</h1>
        </div>
        <div className='contact-content-container container'>
          <div className='contact-text-container'>
            <p>
              The Prologics team is dedicated to the success of your business.
              Contact us today, and we will craft a solution that builds on your
              expertice and expands your scope of treatment.
            </p>
            <p>
              Prologics LLC
              <br/>
              370 Mallory Station Rd, Suite 504
              <br/>
              Franklin, TN 37067
              <br/>
              615.778.0488
              <br/>
              info@prologics.net
            </p>
          </div>
          <div>
            <iframe
            title='map'
            className='open-map'
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-86.8249833583832%2C35.949167225977284%2C-86.81425452232361%2C35.955585477471814&amp;layer=mapnik&amp;marker=35.952376416905764%2C-86.8196189403534"
            style={this.state.mapStyling}
            >
            </iframe>
            <br/>
            <small>
              <a href="https://www.openstreetmap.org/?mlat=35.95238&amp;mlon=-86.81962#map=17/35.95238/-86.81962">
                View Larger Map
              </a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
