import React from 'react';
import './Brachy.scss';

class Brachytherapy extends React.Component {
  render() {
    return (
      <div className='brachytherapy-page'>
        <div className='brachy-banner'>
          <h3 className='sub-heading container'>
            Published studies indicate that the morbidity associated with
            brachytherapy is far less than with surgery. Prologics can conduct
            an assessment on your facility to introduce and improve this service line.
          </h3>
        </div>
        <div className='container brachy-content-container'>
          <h1 className='brachy-title'>Brachytherapy Services</h1>
          <p className='brachy-text'>
            Brachytherapy offers an alternative to traditional external
            beam radiation and can be used in conjunction with external
            beam radiation in prostate cancer. Published studies indicate that
            the morbidity associated with brachytherapy is far less than with
            surgery and the quality of life post-procedure is clinically far
            better.
          </p>
          <p className='brachy-text'>
            Whether you are a hospital or Ambulatory Surgery Center (ASC)
            seeking to establish a new program or retool your current one,
            consider the Prologics Brachytherapy Program. This program can be
            turnkey and was designed for facilities to improve their clinical outcomes,
            eliminate capital expenses and make it easy for staff to impliment as the
            Prologics team handles every aspect of the process.
          </p>
          <div className='video-container'>
            <iframe
              className='video'
              title='Description of Prostate Brachytherapy'
              src='https://www.youtube.com/embed/YYe3FEUft1I'
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
          <p className='brachy-text brachy-servics-list-title'>
            Our brachytherapy services include:
          </p>
            <ul className='brachy-service-list'>
              <li>seed and ancillary supply provision</li>
              <li>physician proctoring</li>
              <li>equipment leasing</li>
              <li>remote dosimetry</li>
              <li>licensing assistance</li>
              <li>comprehensive reimbursement services</li>
            </ul>
          <p className='brachy-text'>
            Our customers find brachytherapy not only clinically excellent, but
            financially beneficial to patients, facilities, and payors.
            Our CPT/HCPCS Map ensures that our customers capture all of their revenue codes.
            Our reimbursement team are experts in setting up and managing
            brachytherapy reimbursement for Medicare and commercial payers.
          </p>
          <h4 className='brachy-sub-title'>Brachytherapy in Ambulatory Surgery Centers</h4>
          <p className='brachy-text'>
            Ambulatory Surgery Centers are often operating below capacity. Brachytherapy
            is a treatment option that can increase utilization. There are two
            common issues that we find working with ASCs. The first is a concern
            that the facility does not currently have the volume to justify the
            expenditure for brachytherapy equipment. The second is a staffing concern
            over how a new procedure will change their responsibilities. Our leasing
            program alleviates capital equipment concerns and the Prologics team members
            can manage the details required to facilitate the program.
          </p>
          <h4 className='brachy-sub-title'>Brachytherapy in Hospitals</h4>
          <p className='brachy-text'>
            Many hospitals that have a long-standing brachytherapy program are unaware
            of how changing reimbursement has affected the profitability of their program.
            If your facility has a current program in place, our team is available
            to conduct an assessment to improve the profitability of this service line.
          </p>
        </div>
      </div>
    );
  }
}

export default Brachytherapy;
