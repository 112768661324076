import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import logo from '../../imgs/Prologics_logo.png';
import './MyNav.scss';

class MyNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar color='dark' dark expand='md'>
          <NavbarBrand href='/' className='brand-name'>
            <img
              src={logo}
              alt='logo'
              className='nav-logo'
            />
            Prologics Healthcare
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
            <NavItem>
                <NavLink href='/'>Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/about'>About</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Services
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href='/brachytherapy'>
                    Brachytherapy
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href='/RadiationTherapy'>
                    Radiation Therapy Centers
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href='/ASC_Management'>
                    ASC Management
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href='/Revenue_Cycle_Management'>
                    Revenue Cycle Management
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href='/Contact_Us'>Contact Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default MyNav;
