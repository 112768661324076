import React from 'react';
import './RCM.scss';

class RCM extends React.Component {
  render() {
    return (
      <div>
        <div className='rcm-banner'>
          <h3 className='sub-heading container'>
            Can your billing team beat our metrics? Out team
            consistently far exceeds industry standards, and results
            in significant improvements to your cash flow.
          </h3>
        </div>
        <div className='container'>
          <h1 className='rcm-title'>Revenue Cycle Management</h1>
          <p>
            The Prologics team currently averages a days sales outstanding
            (DSO) of less than 32 days and a bad debt percentage of less
            than one percent. This performance far exceeds industry
            standards and results in significant improvements to our
            clients’ cash flow. With well over 100 years of combined
            experience in our reimbursement and financial staffs, our team
            can have a significant impact your IMRT center or ASC.
          </p>
          <p>
            Successful reimbursement management requires a dedicated team
            who continually monitor intake, insurance verifications,
            claims submissions and collections processes. They quite
            simply keep their eye on the ball every day because keeping
            cash flowing necessitates the management of an extreme amount
            of details. These details include:
          </p>
            <div className='rcm-ul'>
              <span className='rcm-li'>
                <i className='far fa-handshake rcm-icon fa-4x' id='handshake-icon'></i>
                <p>credentialing</p>
              </span>
              <span className='rcm-li'>
                <i className='far fa-id-card rcm-icon fa-4x' id='card-icon'></i>
                <p>insurance verification</p>
              </span>
              <span className='rcm-li'>
                <i className='fas fa-check-double rcm-icon fa-4x' id='check-icon'></i>
                <p>pre-authorizations and pre-certifications</p>
              </span>
              <span className='rcm-li'>
                <i className='fas fa-newspaper rcm-icon fa-4x' id='billing-icon'></i>
                <p>claims billing</p>
              </span>
              <span className='rcm-li'>
                <i className='fas fa-file-contract rcm-icon fa-4x' id='claim-icon'></i>
                <p>claims adjudication</p>
              </span>
              <span className='rcm-li'>
                <i className='fas fa-money-bill-wave rcm-icon fa-4x' id='dollar-icon'></i>
                <p>payment posting and patient statements</p>
              </span>
              <span className='rcm-li'>
                <i className='fas fa-phone-volume rcm-icon fa-4x' id='phone-icon'></i>
                <p>Third party payer and patient communications</p>
              </span>
              <span className='rcm-li'>
                <i className="fas fa-comments-dollar rcm-icon fa-4x"></i>
                <p>Payor contract negotiations</p>
              </span>
            </div>
          <p>
            If you would like a complementary assessment of billing,
            collections and/or reimbursement functions please contact us at
            615.778.0488
          </p>
        </div>
      </div>
    );
  }
}

export default RCM;
