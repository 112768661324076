import React from 'react';
import {
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';
import './MyFooter.scss';

class MyFooter extends React.Component {
  componentDidMount() {
    window.setTimeout(this.props.heightGetter, 100);
  }

  render() {
    return (
      <div id='footer'>
        <Navbar color='dark' dark expand='md'>
          <div className='footer-text'>
            &#x24B8; 2019 Prologics LLC.
          </div>
            <Nav className='ml-auto' navbar>
              <NavItem className='footer-text'>
                Prologics LLC | 370 Mallory Station Rd, Suite 504 |
                Franklin, TN 37067 | 615.778.0488 | info@prologics.net
              </NavItem>
            </Nav>
        </Navbar>
      </div>
    );
  }
}

export default MyFooter;
